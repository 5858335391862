import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { IoChevronForward } from "react-icons/io5";
import { CEO, DIRECTOR, VP_SERVICE, MANAGER, HeadOfDigitalMarketing, HeadOfLegacyTech } from "../../assets/images";
import "./Our-Core-Team-Leaders.css";
import { Fade } from "react-awesome-reveal";

const CoreTeam = () => {
    const cardInfo = [
        {
            title: "Siva Ramar",
            role: "Chief Executive Officer",
            IMG: CEO
        },
        {
            title: "Soundarya",
            role: "Head of Digital Marketing",
            IMG: HeadOfDigitalMarketing
        },
        {
            title: "Rajapandi",
            role: "Head of Legacy Tech",
            IMG: HeadOfLegacyTech
        },
       
    ];

    return (
        <div className="mt-100">
            <Fade duration={0} direction="right">
            <div className="section_core">
                <Container>
                <Fade direction='up' duration={0} >
                    <div className='core-team-header'>
                        <h2>Our Core Team Leaders</h2>
                        <Row>
                            <Col lg={6}> 
                            <p className="">Meet an exceptional team of technology advisors, growth facilitators, and subject matter experts with proven experience.</p>
                             </Col>
                        </Row>
                       
                    </div>
                    </Fade>

                    <div className="leaders_row">
                        {cardInfo.map(({ title, IMG, role }, index) => {
                            return (
                                
                               
                                    <div className="core-team-card ">
                             
                                        <img className="core-team-icons" src={IMG} alt={title} />
                                        <h2>{title}</h2>
                                        <h3>{role}</h3>
                                       
                                    </div>
                                
                                
                            );
                        })}
                    </div>

                </Container>
            </div>
            </Fade>
        </div>
    );
};

export default CoreTeam;
