import React, { useEffect } from "react";
import Banner from "../../components/banner/banner";
import { CASE_BG_1, CASE_IMG_1, PHARM_SUBBANNER } from "../../assets/images";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import RelatedCaseStudy from "../../components/case-studies/RelatedCaseStudy";
import ContactNew from "../../components/contact-form/ContactNew";
import Footer from "../../components/footer/Footer";
import HeaderSeo from "../../components/HeaderSeo/Headerseo";
import { CaseStudySeo } from "../../utils/Seo";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { SimpleCard } from "../../components/simple-card/SimpleCard";
import './case-study.css'
import CaseResults from "../../components/case-study-result/CaseResults";
import CaseCard from "../../components/case-card/CaseCard";
import ServiceSubBanner from "../../components/service-sub-banner/ServiceSubBanner";

const CaseStudy = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const simplecardData = [
    {
      title: "Multi-Vendor Dashboard",
      content:
        "The platform integrated a centralized dashboard where vendors could manage their product listings, inventory, and orders.",
    },
    {
      title: "Order Processing Streamlining",
      content:
        "An automated order processing system allows orders to be seamlessly assigned to the relevant vendors, improving order fulfilment speed.",
    },
    {
      title: "Automated Vendor Onboarding",
      content:
        "A streamlined onboarding process was introduced, including digital background checks, and product verification.",
    },
    {
      title: "Quality Control Standards",
      content:
        "Established strict quality control guidelines, ensuring vendors met certain criteria before products were listed.",
    },
  ];

  const card_data = [
    {
        id:"01",
        title:"Enhanced User Experience",
        content:"Customers found it easier to discover relevant products through the improved categorization system."
    },
    {
        id:"02",
        title:"Quality Assurance",
        content:"The quality control standards improved the platform's credibility and customer trust."
    },
    {
        id:"03",
        title:"Vendor Engagement",
        content:"Vendors could manage their products and inventory directly, reducing manual intervention from the platform's administrators."
    },
    {
        id:"04",
        title:"Instant Order Fulfilment",
        content:"Orders were processed and dispatched faster due to streamlined vendor coordination, enhancing customer satisfaction."
    },
    {
        id:"05",
        title:"Improved Customer Experience",
        content:"Customers benefited from accurate product availability and faster order processing, leading to enhanced satisfaction"
    }

  ]

   const caseCardData =[

    {
        title:"Vendor Onboarding Complexity",
        content:"The existing vendor onboarding process was time-consuming and lacked efficiency. Manual verification and product listing procedures caused delays in vendor integration."
    },
    {
        title:"Product Diversity Management",
        content:"Managing a wide variety of products from multiple vendors proved to be a logistical challenge. Inconsistent product categorization and quality led to customer dissatisfaction."
    },
    {
        title:"Quality Control",
        content:"The existing vendor onboarding process was time-consuming and lacked efficiency. Manual verification and product listing procedures caused delays in vendor integration."
    },

   ]


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="work_page ">
        <HeaderSeo data={CaseStudySeo} />
        <Newnavigation isblack={true} />
        <div id="Overview">
          <Banner
            bg={CASE_BG_1}
            smalltext={"Case Study - 1"}
            title={"E-Commerce"}
            img={CASE_IMG_1}
            posistion={"end"}
          />
        </div>
        <section className="mt-75 case_study">
          <Container>
            <Row>
              <Col lg={12} className="">
                <div className="content-view">
                  <div id="Overview" className="overview_content">
                    <Fade direction="up" duration={0}>
                      <div>
                        <p>
                          <span>The success of an e-commerce platform</span>{" "}
                          depends not only on its technical aspects but also on
                          effective marketing, customer service, and continuous
                          improvement based on user feedback and market trends.
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="case_study_section mt-75 ">
              <h2 className="case_study_main_title">Challenges & Potential</h2>

              <h1 className="case_study_main_subtitle">Navigating the Complexities of <br />  E-commerce</h1>
              <div className="mt-50">
               <CaseCard cardInfo={caseCardData} />
              </div>
            
            </div>





            <div className="case_study_section  mt-75 ">
              <h2 className="case_study_main_title">Solution & Implementation</h2>

              <h1 className="case_study_main_subtitle">Modernizing E-Commerce</h1>
              <div className="mt-50">
              <Row>
                {simplecardData?.map((item, index) => (
                  <Col lg={6} key={index} className="mb-24">
                    <SimpleCard
                      title={item?.title}
                      content={item?.content}
                      height={"100%"}
                    />
                  </Col>
                ))}
              </Row>
              </div>
            
            </div>

            <div className="case_study_section  mt-75 mb-60">
              <h2 className="case_study_main_title">Results</h2>

              <h1 className="case_study_main_subtitle">Revolutionizing </h1>
              <div className="mt-50">

                <CaseResults data={card_data}/>
              
              </div>
            
            </div>
          </Container>


        </section>
        <div className="case_page">
        <ServiceSubBanner bg={PHARM_SUBBANNER} title="Kickstart Your Dream Project With Us" contact="Contact Us"
        contentPadding="20%"
        padding = "20%" /> 
         </div>
        <RelatedCaseStudy filterCol="E-Commerce" />
            <ContactNew />
        <Footer />

      </div>
    </motion.div>
  );
};

export default CaseStudy;


// 
{/* */}
