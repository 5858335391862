import React, { useEffect } from "react";
import Newnavigation from "../../components/new-navigation/new_navigation";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import './static.css'
import ContactNew from "../../components/contact-form/ContactNew";

const TermsConditions = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="static_content">
    <Newnavigation />
    <Container>
      <Row>
        <Col lg={{ span: 12 }}>
          <div className="letter_box">
            <h2 className="main_head">Technogenesis Terms of Services</h2>
            <h6>
            The following are terms of a legal agreement between you and Mobisoft Infotech. Here, we clearly define aspects of our business relationship with you.
            </h6>

            <div className="main_content">
                <div>
                    <ul>
                        <li>All site content (text and multimedia) will be the sole responsibility of the client to provide to Mobisoft Infotech. Such should be provided prior to commencing the work.</li>
                        <li>The client is solely responsible to take a proper back-up of all content on their site prior to letting Mobisoft Infotech undertake the required course of action towards meeting the contract. Any loss or damage to existing data shall not be a responsibility of Mobisoft Infotech under any circumstances.</li>
                        <li>The Contract does not hold Mobisoft Infotech responsible for any data entry, web hosting or custom artwork/graphics related work/tasks unless otherwise specifically mentioned, paid for and agreed to by both the parties towards such. Any artwork, images, or text supplied and/or designed by Mobisoft Infotech on behalf of the client, will remain the property of Mobisoft Infotech and/or it's suppliers unless otherwise agreed</li>
                        <li>While Mobisoft Infotech will do its best to achieve all deliveries within the estimated time, there may, at times, be the need to extend or adjust time in cases of any unavoidable and non-forecasted situations like those of deployment issues, dependencies, 3rd-Party support, development bottlenecks, resource unavailability due to emergency, communication delays and the like.</li>
                        <li>Mobisoft Infotech will provide the Client an opportunity to review the appearance and content of the Website during the design and once they are completed. Mobisoft Infotech shall wait for a period of 7 days to hear any feedback on such shared work/outputs from the client. In the event of client not reply within this period, such material will be deemed to have been automatically accepted and approved by the Client</li>
                        <li>
                        The Client retains the copyright to data, files and graphic logos provided by the Client and grants Mobisoft Infotech the rights to publish and use such material. The Client must obtain permission and rights to use any information or files that are copyrighted by a third party. The Client is further responsible for granting Mobisoft Infotech permission and rights for use of the same and agrees to indemnify and hold harmless Mobisoft Infotech from any and all claims resulting from the Client's negligence or inability to obtain proper copyright permissions. A contract for Web site design and/or placement shall be regarded as a guarantee by the Client to Mobisoft Infotech that all such permissions and authorities have been obtained. Evidence of permissions and authorities may be requested.
                        </li>
                        <li>
                        Mobisoft Infotech will not accept responsibility for any alterations caused by the Client or a third party occurring to the Client's pages/website once installed/deployed. Such alterations include, but are not limited to additions, modifications, or deletions. Mobisoft Infotech may require a one-off Web Development charge before resolving any issues that may arise.
                        </li>
                    </ul>
                </div>
            
            
          
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <ContactNew />
    <Footer />
  </div>
  )
}

export default TermsConditions